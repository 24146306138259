export default [
  {
    key: "no",
    label: "#",
    thStyle:{color:"white",width: "4% !important", textAlign: "center"}
  },
  {
    key: "serial",
    label: "Serial No.",
    thStyle:{color:"white",width: "12% !important", textAlign: "center"}
  },
  
  { key: "hwTypeName", label: "Type", sortable: true , thStyle:{color:"white",width: "10% !important", textAlign: "center"}},
  { key: "hwBrandName", label: "Brand", sortable: true ,thStyle:{color:"white",width: "10% !important", textAlign: "center",}}, 
  { key: "hwModelName", label: "Model", sortable: true ,thStyle:{color:"white",width: "10% !important", textAlign: "center",}}, 
  
  
  {
    key: "actionDetail",
    label: "Last status",
    sortable: false,
    thStyle: {  textAlign: "center" ,color:"white"},
  },
  { key: "createAt", label: "Date", sortable: true , thStyle:{color:"white",width: "10% !important", textAlign: "center"}},
  {
    key: "action",
    label: "รายละเอียด",
    sortable: false,
    thStyle: { width: "7% !important", textAlign: "center", color:"white" },
  },
];
